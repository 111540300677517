import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {
  getSecurityHeaders,
  updateSecurityHeaders,
} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import helper from '../../../../../../utils/Helper'

// In sync with backend defaults
const defaultSettings = {
  'Strict-Transport-Security': 'max-age=31536000; includeSubdomains; preload',
  'X-Content-Type-Options': 'nosniff',
  'Content-Security-Policy':
    "default-src * 'unsafe-inline' 'unsafe-eval'; script-src * 'unsafe-inline' 'unsafe-eval'; connect-src *; img-src * data: blob:; frame-src *; style-src * 'unsafe-inline'; font-src * 'unsafe-inline';",
  'X-Frame-Options': 'sameorigin',
  'Referrer-Policy': 'strict-origin-when-cross-origin',
  'Permissions-Policy': 'interest-cohort=()',
}

const SecurityHeader: React.FC = () => {
  const {applicationId} = useParams()
  const [state, setState] = useState(false)
  const [settings, setSettings] = useState<Record<string, string>>({})

  const [selectedHeaders, setSelectedHeaders] = useState<Set<string>>(new Set())
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)

  const [initialSettings, setInitialSettings] = useState<Record<string, string>>({})
  const [initialSelectedHeaders, setInitialSelectedHeaders] = useState<Set<string>>(new Set())
  const [initialState, setInitialState] = useState(false)

  const [isUpdating, setIsUpdating] = useState(false)
  const [errors, setErrors] = useState<Record<string, string>>({}) // For storing error messages

  const fetchData = async () => {
    try {
      const data = await getSecurityHeaders(applicationId ?? '')
      setState(data.state)
      setInitialState(data.state)

      const settings = {...defaultSettings, ...data.settings}
      setSettings(settings)
      setInitialSettings(settings)

      // If settings are empty, select all headers
      const headersToSelect = new Set(Object.keys(data.settings))
      setSelectedHeaders(headersToSelect)
      setInitialSelectedHeaders(headersToSelect)
    } catch (error) {
      notifyError('Failed to fetch security headers.')
    }
  }

  useEffect(() => {
    fetchData()
  }, [applicationId])

  const handleSave = async () => {
    setIsUpdating(true)
    try {
      let filteredSettings: Record<string, string> = {}

      for (const setting of Object.keys(settings)) {
        if (selectedHeaders!.has(setting)) {
          filteredSettings[setting] = settings[setting]
        }
      }

      const success = await updateSecurityHeaders(applicationId ?? '', {
        state,
        settings: filteredSettings,
      })

      if (success) {
        notifySuccess('Security Headers updated successfully')
        setIsSaveButtonActive(false)

        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Failed to update Security Headers')
      }
    } catch (error) {
      notifyError('Failed to update Security Headers')
    } finally {
      setIsUpdating(false)
    }
  }

  const detectChanges = (
    updatedSettings: Record<string, string>,
    updatedSelectedHeaders: Set<string>,
    state: boolean
  ) => {
    const hasSettingsChanged = !helper.deepEqual(updatedSettings, initialSettings)

    const hasSelectedHeadersChanged = !helper.deepEqual(
      Array.from(updatedSelectedHeaders),
      Array.from(initialSelectedHeaders)
    )

    const isStateChange = state !== initialState

    const hasSelectedHeaders = updatedSelectedHeaders.size > 0
    const allHeadersFilled = Array.from(updatedSelectedHeaders).every((header) =>
      updatedSettings[header]?.trim()
    )

    setIsSaveButtonActive(
      (isStateChange || hasSettingsChanged || hasSelectedHeadersChanged) &&
        hasSelectedHeaders &&
        allHeadersFilled
    )
  }

  const handleInputChange = (key: string, value: string) => {
    const updatedSettings = {...settings, [key]: value}
    setSettings(updatedSettings)

    if (!value.trim()) {
      setErrors((prev) => ({...prev, [key]: 'This field is required'}))

      setSelectedHeaders((prev) => {
        const newSet = new Set(prev)
        newSet.delete(key)
        return newSet
      })
    } else {
      setErrors((prev) => {
        const newErrors = {...prev}
        delete newErrors[key]
        return newErrors
      })

      setSelectedHeaders((prev) => new Set(prev).add(key))
    }

    detectChanges(updatedSettings, selectedHeaders, state)
  }

  const handleCheckboxChange = (key: string, checked: boolean) => {
    const updatedSelectedHeaders = new Set(selectedHeaders)

    if (checked) {
      updatedSelectedHeaders.add(key)
    } else {
      updatedSelectedHeaders.delete(key)
    }

    setSelectedHeaders(updatedSelectedHeaders)
    detectChanges(settings, updatedSelectedHeaders, state)
  }

  const toggleState = async () => {
    setState(!state)
    detectChanges(settings, selectedHeaders, !state)
  }

  return (
    <div className='container-fluid'>
      <TabHeader heading='Security Headers' />

      <TabBody>
        <div className='text-primary'>
          Configure security headers for your application to enhance protection against various
          threats.
        </div>

        <div className='row mb-10 mt-5'>
          <div className='col-lg-12'>
            <div className='d-flex align-items-center'>
              <div className='me-3'>
                <strong>Status:</strong>
              </div>
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  checked={state}
                  onChange={toggleState}
                  style={{
                    backgroundColor: state ? 'green' : 'grey',
                    borderColor: state ? 'green' : 'grey',
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {state && (
          <form className='mt-2'>
            {Object.keys(settings).map((setting) => (
              <div key={setting} className='form-group row align-items-center mb-3'>
                <div className='col-md-1 form-check form-check-custom form-check-solid'>
                  <input
                    type='checkbox'
                    checked={selectedHeaders?.has(setting)}
                    onChange={(e) => handleCheckboxChange(setting, e.target.checked)}
                    className='form-check-input'
                  />
                </div>
                <label className='col-lg-2 text-dark fw-semibold' style={{paddingLeft: '0px'}}>
                  {setting}:
                </label>
                <div className='col-lg-9'>
                  <input
                    type='text'
                    className='form-control'
                    value={settings[setting] || ''}
                    onChange={(e) => handleInputChange(setting, e.target.value)}
                    disabled={!selectedHeaders?.has(setting)}
                    placeholder='Enter value'
                  />
                  {errors[setting] && <span className='text-danger'>{errors[setting]}</span>}
                </div>
              </div>
            ))}
          </form>
        )}

        <div className='row mt-5'>
          <div className='col-lg-12'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleSave}
              disabled={!isSaveButtonActive || isUpdating}
            >
              {isUpdating ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </TabBody>
    </div>
  )
}

export default SecurityHeader
