function deepEqual(obj1: any, obj2: any): boolean {
  // If both values are strictly equal, return true
  if (obj1 === obj2) {
    return true
  }

  // Check if both values are objects, if not return false
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false
  }

  // Get keys from both objects
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // If the number of keys is different, objects are not equal
  if (keys1.length !== keys2.length) {
    return false
  }

  // Compare each key-value pair recursively
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export default {deepEqual}
